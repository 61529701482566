<template>
    <div>
        <header class="text-l font-semibold mb-3">Huvud Leverantörer</header>
        <el-form ref="form" :model="form" label-width="200px" class="w-full" width="100%" label-position="top" :rules="rules" v-loading="$waiting.is('updating')">
            <el-form-item label="Företagsnamn" prop="name">
                <el-input v-model="form.name" />
            </el-form-item>
            <el-form-item label="Smeknamn">
                <el-input v-model="form.alias" />
            </el-form-item>
            <el-form-item label="Organisationsnummer" prop="registrationNumber">
                <el-input v-model="form.registrationNumber" />
            </el-form-item>
            <el-form-item label="E-post" prop="email">
                <el-input v-model="form.email" />
            </el-form-item>
            <el-form-item label="Standardbokningskonto privat">
                <el-input v-model="form.privateCompanyDefaultAccount" />
            </el-form-item>
            <el-form-item label="Standardbokningskonto företag">
                <el-input v-model="form.stockCompanyDefaultAccount" />
            </el-form-item>
            <el-form-item label="Land" prop="countryId">
                <el-select class="w-full" v-model="form.countryId">
                    <el-option v-for="country in countryOptions" :label="country.name" :value="country.value" :key="country.value" />
                </el-select>
            </el-form-item>
            <div class="flex justify-end mt-5">
                <el-button v-if="showSavebtn" size="mini" @click="onSave" type="primary">Spara</el-button>
                <el-button v-else size="mini" @click="onSubmit" type="primary">Sammanfoga</el-button>
            </div>
        </el-form>
    </div>
</template>
<script>
import Api from "../suppliers.api";

export default {
    props: {
        supplierId: {
            type: Number,
            default: 0,
        },

        multipleSelection: {
            type: Array,
        },
    },

    watch: {
        supplierId: {
            immediate: true,
            async handler() {
                await this.getSupplierDetails();
            },
        },

        multipleSelection: {
            immediate: true,
            async handler() {
                if (this.multipleSelection.length === 0 && this.supplierId != 0) {
                    this.showSavebtn = true;
                } else {
                    this.showSavebtn = false;
                }
            },
        },
    },

    data() {
        return {
            form: {},
            countryOptions: [],
            showSavebtn: true,
            rules: {
                name: [{ required: true, message: "Namn saknas", trigger: "blur" }],
                registrationNumber: [{ required: true, message: "Organisationsnummer saknas", trigger: "blur" }],
                countryId: [{ required: true, message: "Land saknas", trigger: "blur" }],
                email: [{ type: "email", message: "Vänligen ange korrekt e-postadress", trigger: "blur" }],
            },
        };
    },

    async created() {
        this.countryOptions = await Api.getCountryList();
    },

    methods: {
        async onSave() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            this.$waiting.start("updating");
            await Api.updateSupplier(this.form);
            this.$emit("completed");
            this.$notify.success({ title: "Utfört" });
            this.$waiting.end("updating");
        },

        async onSubmit() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));

            if (this.multipleSelection.length === 0) {
                await this.$dialog.html().alert(`Välj leverantörer att slå samman`);
                return;
            } else if (this.multipleSelection.length === 1) {
                await this.$dialog.html().alert(`Välj mer än en leverantör`);
                return;
            }
            if (!this.multipleSelection.some(item => item.id === this.supplierId)) {
                await this.$dialog.html().alert(`Vänligen kryssa i kryssrutan för huvudleverantör`);
                return;
            }

            this.mergeSupliers();
        },

        async mergeSupliers() {
            this.$waiting.start("updating");
            const mergeSuppliersDto = {
                headSupplierId: this.supplierId,
                mergeSupplierIds: Array.from(this.multipleSelection.map(item => item.id).filter(id => id !== this.supplierId)),
                headSupplierObj: this.form,
            };
            await Api.mergeSuppliers(mergeSuppliersDto);
            this.$emit("completed");
            this.$notify.success({ title: "Utfört" });
            this.$waiting.end("updating");
        },

        async getSupplierDetails() {
            this.$waiting.start("updating");
            this.form = await Api.getSupplierDetails(this.supplierId);
            this.$waiting.end("updating");
        },
    },
};
</script>
